<template>
  <div>
    <a-button-group>
      <a-button type="primary" size="small" @click="createModalVisible = true">
        {{ planType }}
      </a-button>
    </a-button-group>

    <CreateFormModal v-model="createModalVisible" :item="item" @create="create" @cancel="createModalVisible = false" />
  </div>
</template>

<script>
export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
  },
  props: ["item"],
  data() {
    return {
      createModalVisible: false,
    };
  },
  computed: {
    planType() {
      if (this.item.maintenance_plan_item.type === "inspection") {
        return "点检";
      }

      if (this.item.maintenance_plan_item.type === "maintenance") {
        return "保养";
      }

      return null;
    },
  },
  methods: {
    create(item) {},
  },
};
</script>

<style scoped></style>
